import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"

const SitemapIndex = () => (
    <Layout>
        <SEO title="Sitemap" />

        <PageTitle title="Sitemap"/>

        <ul className={"ml-4"}>
            <li>
                <Link to={"/"}>Home</Link>
            </li>
            <li>
                <Link to={"/about"}>About</Link>
            </li>
            <li>
                <Link to={"/services"}>Services</Link>
            </li>
            <li>
                <Link to={"/case-studies"}>Case Studies</Link>
                <ul>
                    <li>
                        <Link to={"/case-studies/gubbins-lane"}>Gubbins Lane</Link>
                    </li>
                    <li>
                        <Link to={"/case-studies/hoe-street"}>Hoe Street</Link>
                    </li>
                    <li>
                        <Link to={"/case-studies/orsett-heath-academy"}>Orsett Heath Academy</Link>
                    </li>
                </ul>
            </li>
            <li>
                <Link to={"/gallery"}>Gallery</Link>
            </li>
            <li>
                <Link to={"/contact"}>Contact</Link>
            </li>
            <li>
                <Link to={"/terms"}>Terms & Conditions</Link>
            </li>
            <li>
                <Link to={"/privacy"}>Privacy & Cookies Policy</Link>
            </li>
        </ul>
    </Layout>
)

export default SitemapIndex
